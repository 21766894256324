<!-- eslint-disable eqeqeq -->
<!-- eslint-disable vue/html-indent -->
<!-- eslint-disable vuetify/no-legacy-grid -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="main_div_admin_dashbord">
    <div id="myTopnav" ref="myTopnav" class="topnav">
      <!-- <a href="" class=""></a> -->
      <a
        :class="computedMemberListClass"
        @click="clickNavBtn('/MembersList')"
        >{{ $t("memberList.memberManagement") }}</a>
      <a @click="clickNavBtn('/Statistics')">{{ $t("memberList.statisticalManagement") }}
      </a>
      <a class="log_out_admin_dashbord" @click="logoutAdmin">{{
        $t("memberList.logout")
      }}</a>
      <!-- <a href="javascript:void(0);" class="icon">
              <i class="v-icon notranslate mdi mdi-menu theme--light"></i>
          </a> -->
    </div>
    <div class="main_admin_dashbord_new">
      <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        :timeout="snackbar.timeout"
        :top="snackbar.position === 'top'"
        rounded="pill"
        width="877.27px"
      >
        <v-row class="pr-4" align="center">
          <v-icon class="pr-3" dark large>
            {{ snackbar.icon }}
          </v-icon>
          <v-row column>
            <div>{{ $t(snackbar.text) }}</div>
          </v-row>
        </v-row>
        <v-btn
          v-if="snackbar.timeout === 0"
          icon
          @click="snackbar.visible = false"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-snackbar>
      <div class="main_admin_dashbord_new_first">
        <ul>
          <li class="mebmber_li_item">
            <a style="pointer-events: none">{{ $t("memberList.title") }}</a>
          </li>
        </ul>
      </div>
      <div class="main_admin_dashbord_new_second">
        <base-card>
          <v-container fluid>
            <div class="row content">
              <div
                class="
                  col-sm-12 col-md-12 col-xl-12
                  xol-lg-12
                  admin_dashbord_content
                "
              >
                <v-dialog v-model="licenseNumberalreadyExistModal" width="500">
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <center>
                        {{
                          $t("memberList.warningforlicenseNumberAlreadyExist")
                        }}
                      </center>
                    </v-card-title>
                    <br>
                    <v-card-text style="color: black; font-size: 18px">
                      <center>
                        {{ $t("memberList.licenseNumberAlreadyExist") }}
                      </center>
                    </v-card-text>

                    <v-divider />

                    <center>
                      <v-card-actions style="display: block">
                        <v-spacer />

                        <v-btn
                          color=""
                          style="background-color: #d9d9d9"
                          @click="licenseNumberalreadyExistModal = false"
                        >
                          {{ $t("memberList.close") }}
                        </v-btn>
                      </v-card-actions>
                    </center>
                  </v-card>
                </v-dialog>

                <h5 class="fw-700">
                  {{ $t("memberList.memberList") }}
                </h5>
                <v-row class="admin_dashbord_content_member_list_row_first">
                  <v-col
                    class="admin_dashbord_content_member_list"
                    cols="12"
                    md="3"
                    sm="6"
                  >
                    <v-menu
                      v-model="startMenu"
                      class="v_menu_start_date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }" outlined>
                        <v-text-field
                          v-model="startDate"
                          label=""
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          outlined
                          style="min-height: auto"
                          @click:append="on.click"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        class="startdate_picker"
                        @change="endDateValidation"
                        @input="startMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    class="
                      admin_dashbord_content_member_list
                      admin_dashbord_content_member_second_list
                    "
                    cols="12"
                    md="3"
                    sm="6"
                  >
                    <v-menu
                      v-model="endMenu"
                      class="v_menu_start_date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label=""
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          outlined
                          @click:append="on.click"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        width="290px"
                        @change="endDateValidation"
                        @input="endMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <!-- Style Changes -->
                  <!-- <v-col class="date_time_week_btngroup_main_class" cols="12" md="6" sm="12">
                    <div class="date_time_week_btngroup" aria-label="Basic outlined example">
                      <v-btn-toggle v-model="toggle_exclusive" class="date_time_week_btngroup_toggle" mandatory>
                        <v-btn type="button" style="border: 1px solid black !important;"
                          class="date_time_week_btngroup_button" @click="filterDate('today')">
                          {{
    $t("memberList.today")
}}
                        </v-btn>
                        <v-btn type="button" style="border: 1px solid black !important;"
                          class="date_time_week_btngroup_button" @click="filterDate('1week')">
                          {{
    $t("memberList.week")
}}
                        </v-btn>
                        <v-btn type="button" style="border: 1px solid black !important;"
                          class="date_time_week_btngroup_button" @click="filterDate('1month')">
                          {{
    $t("memberList.onemonth")
}}
                        </v-btn>
                        <v-btn type="button" style="border: 1px solid black !important;"
                          class="date_time_week_btngroup_button" @click="filterDate('6month')">
                          {{
    $t("memberList.sixmonth")
}}
                        </v-btn>
                        <v-btn type="button" style="border: 1px solid black !important;"
                          class="date_time_week_btngroup_button" @click="filterDate('1year')">
                          {{
    $t("memberList.year")
}}
                        </v-btn>
                        <v-btn type="button" style="border: 1px solid black !important;"
                          class="date_time_week_btngroup_button" @click="filterDate('all')">
                          {{
    $t("memberList.all")
}}
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                  </v-col> -->

                  <v-col
                    class="date_time_week_btngroup_main_class"
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <div
                      class="date_time_week_btngroup"
                      aria-label="Basic outlined example"
                    >
                      <v-btn-toggle
                        v-model="btnToggle"
                        class="date_time_week_btngroup_toggle"
                        mandatory
                      >
                        <v-btn
                          type="button"
                          style="border: 1px solid black !important"
                          class="date_time_week_btngroup_button"
                          @click="filterDate('today')"
                        >
                          {{ $t("memberList.today") }}
                        </v-btn>
                        <v-btn
                          type="button"
                          style="border: 1px solid black !important"
                          class="date_time_week_btngroup_button"
                          @click="filterDate('1week')"
                        >
                          {{ $t("memberList.week") }}
                        </v-btn>
                        <v-btn
                          type="button"
                          style="border: 1px solid black !important"
                          class="date_time_week_btngroup_button"
                          @click="filterDate('1month')"
                        >
                          {{ $t("memberList.onemonth") }}
                        </v-btn>
                        <v-btn
                          type="button"
                          style="border: 1px solid black !important"
                          class="date_time_week_btngroup_button"
                          @click="filterDate('6month')"
                        >
                          {{ $t("memberList.sixmonth") }}
                        </v-btn>
                        <v-btn
                          type="button"
                          style="border: 1px solid black !important"
                          class="date_time_week_btngroup_button"
                          @click="filterDate('1year')"
                        >
                          {{ $t("memberList.year") }}
                        </v-btn>
                        <v-btn
                          type="button"
                          style="border: 1px solid black !important"
                          class="date_time_week_btngroup_button"
                          @click="filterDate('all')"
                        >
                          {{ $t("memberList.all") }}
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="admin_dashbord_content_member_list_row">
                  <v-col
                    cols="12"
                    md="5"
                    sm="6"
                    class="admin_dashbord_content_member_list"
                  >
                    <v-text-field
                      v-model="inputName"
                      class="member_text_field_slot"
                      outlined
                      :placeholder="$t('memberList.memberName')"
                    />
                  </v-col>
                  <v-col
                    class="inquiry_btn_button_main"
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <!-- Removed coz Suggestion -->
                    <!-- <v-btn outlined class="btn btn-outline-secondary mr-1 w-25" @click="searchNameDate">{{
                  $t("memberList.Inquiry")
              }}</v-btn> -->
                    <v-btn
                      color=""
                      class="inquiry_btn_button mr-1 w-25"
                      @click="searchNameDate"
                    >
                      {{ $t("memberList.Inquiry") }}
                    </v-btn>
                    <!-- Removed coz Suggestion -->
                    <!-- <v-btn outlined class="btn btn-outline-secondary w-25" @click="refreshListWithDateInput">{{
                  $t("memberList.reset")
              }}</v-btn> -->
                    <v-btn
                      class="inquiry_btn_button w-25"
                      @click="refreshListWithDateInput"
                    >
                      {{ $t("memberList.reset") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="datacountClass">
                  {{ $t("memberList.search") }}: {{ datatableCount }}
                </div>
                <br>
                <v-data-table
                  :headers="headers"
                  :items="members"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 15, 20],
                  }"
                  :items-per-page="10"
                  class="elevation-1"
                  dense
                >
                  <template v-slot:[`item.viewLicense`]="{ item }">
                    <!-- <v-icon small class="mr-2" @click="editMember(item.id)">mdi-pencil</v-icon> -->
                    <a @click="editMember(item.id, 'viewLicense')"><img
                        height="25px"
                        width="25px"
                        class="datatable_image_icon ms-3"
                        src="@/assets/images/customImages/viewLicense.png"
                    ></a>
                    <!-- <v-icon small @click="deleteMember(item.id)">mdi-delete</v-icon> -->
                  </template>
                  <template v-slot:[`item.viewdetails`]="{ item }">
                    <!-- <v-icon small class="mr-2" @click="editMember(item.id)">mdi-pencil</v-icon> -->
                    <a @click="editMember(item.id, 'viewdetails')"><img
                        height="25px"
                        width="25px"
                        class="datatable_image_icon"
                        src="@/assets/images/customImages/viewDetails.png"
                    ></a>
                    <!-- <v-icon small @click="deleteMember(item.id)">mdi-delete</v-icon> -->
                  </template>
                </v-data-table>
                <v-dialog
                  v-if="currentMember"
                  v-model="dialog"
                  class="v_dialog_member"
                  persistent
                  max-width="950px"
                  scrollable
                >
                  <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Open Dialog
            </v-btn>
          </template> -->
                  <v-card class="v_dialog_member">
                    <v-card-title>
                      <span class="text-h5">{{ $t(dialogTitle) }}</span>
                    </v-card-title>
                    <hr>
                    <br>
                    <v-card-text class="v_dialog_member">
                      <v-container
                        v-if="dialogText === 'viewDetailContainer'"
                        class="v_dialog_member"
                      >
                        <v-form
                          ref="form"
                          v-model="validDetails"
                          lazy-validation
                        >
                          <v-row class="dialog_member_row_test">
                            <v-col
                              class="dialog_member_col_test"
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              {{ $t("memberList.name") }}
                            </v-col>
                            <v-col
                              class="dialog_member_row_test_down"
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                v-model="currentMember.name"
                                :rules="nameRules.concat(requiredRule)"
                                outlined
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row class="dialog_member_row_test">
                            <v-col
                              class="dialog_member_col_test"
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              {{ $t("memberList.email") }}
                            </v-col>
                            <v-col
                              class="dialog_member_row_test_down"
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                v-model="currentMember.email"
                                class="email_read_only_modal"
                                outlined
                                readonly
                              />
                            </v-col>
                          </v-row>
                          <v-row style="display: none;" class="dialog_member_row_test">
                            <v-col
                              class="dialog_member_col_test"
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              {{ $t("memberList.password") }}
                            </v-col>
                            <v-col
                              class="dialog_member_row_test_down"
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                v-model="currentMember.password"
                                :rules="passwordRules.concat(requiredRule)"
                                outlined
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row class="dialog_member_row_test">
                            <v-col
                              class="dialog_member_col_test"
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              {{ $t("memberList.dateofBirth") }}
                            </v-col>
                            <v-col
                              class="dialog_member_row_test_down"
                              cols="12"
                              sm="6"
                              md="2"
                            >
                              <v-text-field
                                v-model="birthDate.year"
                                :rules="yearRules.concat(requiredRule)"
                                outlined
                                required
                                @keypress="isNumber($event)"
                              />
                            </v-col>
                            <v-col
                              class="dialog_member_row_test_down"
                              cols="12"
                              sm="6"
                              md="2"
                            >
                              <v-select
                                v-model="birthDate.month"
                                class="v_select_birthday_month"
                                width="125px"
                                :items="months"
                                outlined
                                required
                              />
                            </v-col>
                            <v-col
                              class="dialog_member_row_test_down"
                              cols="12"
                              sm="6"
                              md="2"
                            >
                              <v-text-field
                                v-model="birthDate.date"
                                :rules="dayRules.concat(requiredRule)"
                                outlined
                                required
                                @keypress="isNumber($event)"
                              />
                            </v-col>
                          </v-row>
                          <v-row class="dialog_member_row_test">
                            <v-col
                              class="dialog_member_col_test"
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              {{ $t("memberList.licenseType") }}
                            </v-col>
                            <v-col
                              class="dialog_member_row_test_down"
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-select
                                v-model="currentMember.licenseType"
                                outlined
                                :items="licenseTypes"
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row class="dialog_member_row_test">
                            <v-col
                              class="dialog_member_col_test"
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              {{ $t("memberList.licenseNumber") }}
                            </v-col>
                            <v-col
                              class="dialog_member_row_test_down"
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                v-model="currentMember.licenseNumber"
                                :rules="licenseNumberRules.concat(requiredRule)"
                                outlined
                                required
                              />
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <v-container
                        v-else-if="dialogText === 'viewLicenseContainer'"
                        class="v_dialog_member"
                      >
                        <div class="modal-body view_license_ul">
                          <div class="d-inline-block">
                            <ul class="">
                              <li>{{ $t("memberList.name") }}</li>
                              <li>{{ $t("memberList.licenseType") }}</li>
                              <li>{{ $t("memberList.licenseNumber") }}</li>
                              <li>{{ $t("memberList.dateofBirth") }}</li>
                            </ul>
                          </div>
                          <div class="d-inline-block">
                            <ul class="name_details_span">
                              <li>
                                <span>{{ currentMember.name }}</span>
                              </li>
                              <li>
                                <span>{{ currentMember.licenseType }}</span>
                              </li>
                              <li>
                                <span>{{ currentMember.licenseNumber }}</span>
                              </li>
                              <li>
                                <span>{{
                                  currentMember.birth.split("-")[0] +
                                  "." +
                                  currentMember.birth.split("-")[1] +
                                  "." +
                                  currentMember.birth.split("-")[2]
                                }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <!-- <ul>
                    <li>
                      {{ $t("memberList.name") }} </li>
                  </ul>
                  <ul>
                    <li>{{ currentMember.name }}</li>
                  </ul>

                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <ul>
                        <li>
                          {{ $t("memberList.licenseType") }}</li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      {{ currentMember.licenseType }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <ul>
                        <li>
                          {{ $t("memberList.licenseNumber") }}</li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      {{ currentMember.licenseNumber }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <ul>
                        <li>
                          {{ $t("memberList.dateofBirth") }}</li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                      {{ currentMember.birth.split('-')[0] + "." + currentMember.birth.split('-')[1] + "." +
                          currentMember.birth.split('-')[2]
                      }}
                    </v-col>
                  </v-row> -->
                        <br>
                        <!-- <hr /> -->
                        <br>
                        <ul
                          class="
                            view_license_dialog
                            list-group list-group-horizontal
                          "
                        >
                          {{
                            $t("memberList.approvalStatus")
                          }}
                          <br><br>
                          <v-btn-toggle
                            v-model="currentMember.hcpApproval"
                            class="approval_toggle_button"
                            mandatory
                          >
                            <v-btn
                              class="inquiry_btn_button_view_license"
                              value="Waiting for approval"
                            >
                              {{ $t("memberList.waitingforapproval") }}
                            </v-btn>
                            <v-btn
                              class="inquiry_btn_button_view_license"
                              value="Approved"
                            >
                              {{ $t("memberList.approved") }}
                            </v-btn>
                            <v-btn
                              class="inquiry_btn_button_view_license"
                              value="Name discrepancy"
                            >
                              {{ $t("memberList.nameDiscrepancy") }}
                            </v-btn>
                            <v-btn
                              class="inquiry_btn_button_view_license"
                              value="License number mismatch"
                            >
                              {{ $t("memberList.licenseNumberMismatch") }}
                            </v-btn>
                            <v-btn
                              class="inquiry_btn_button_view_license"
                              value="Birth date mismatch"
                            >
                              {{ $t("memberList.birthDateMismatch") }}
                            </v-btn>
                          </v-btn-toggle>
                        </ul>
                        <!-- <div class="view_license_main_footer">
                <v-btn type="button" style="background-color:#D9D9D9;width: 140px;" @click="updateMember">{{ $t("memberList.save") }}</v-btn>
                <v-btn type="button" style="background-color:#D9D9D9;width: 140px;margin-left: 10px;" @click="dialog = false">{{ $t("memberList.close") }}</v-btn>
              </div> -->
                        <!-- <div class="view_license_main_footer modal-footer">
                    <button type="button" class="btn btn-secondary">Save</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div> -->
                        <!--
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <ul>
                        <li>
                          {{ $t("memberList.approvalStatus") }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                      <ul class="view_license_dialog list-group list-group-horizontal">
                        <v-btn-toggle class="approval_toggle_button" v-model="currentMember.hcpApproval" mandatory>

                            <v-btn style="font-size: 14px !important;font-weight: 400px !important;"
                              value="Waiting for approval">
                              {{ $t("memberList.waitingforapproval") }}
                            </v-btn>
                          </li>

                            <v-btn style="font-size: 14px !important;font-weight: 400px !important;" value="Approved">
                              {{ $t("memberList.approved") }}
                            </v-btn>
                          </li>

                            <v-btn style="font-size: 14px !important;font-weight: 400px !important;"
                              value="Name discrepancy">
                              {{ $t("memberList.nameDiscrepancy") }}
                            </v-btn>
                          </li>

                            <v-btn style="font-size: 14px !important;font-weight: 400px !important;"
                              value="License number mismatch">
                              {{ $t("memberList.licenseNumberMismatch") }}
                            </v-btn>
                          </li>

                            <v-btn style="font-size: 14px !important;font-weight: 400px !important;"
                              value="Birth date mismatch">
                              {{ $t("memberList.birthDateMismatch") }}
                            </v-btn>
                          </li>
                        </v-btn-toggle>
                      </ul>
                    </v-col>
                  </v-row> -->
                      </v-container>
                    </v-card-text>

                    <div
                      v-if="viewDetailDeleteButtonShow"
                      style="
                        align-items: center !important;
                        text-align: center !important;
                        margin-bottom: 20px;
                      "
                    >
                      <v-btn
                        type="button"
                        color=""
                        style="background-color: #d9d9d9; width: 140px"
                        @click="updateMember('viewLicense')"
                      >
                        {{ $t("memberList.save") }}
                      </v-btn>
                      <v-btn
                        type="button"
                        color=""
                        style="
                          background-color: #d9d9d9;
                          width: 140px;
                          margin-left: 10px;
                        "
                        @click="dialog = false"
                      >
                        {{ $t("memberList.close") }}
                      </v-btn>

                      <!-- <v-card-actions class="vcardaction_buttons">
                  <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="updateMember">
                    {{ $t("memberList.save") }}
                  </v-btn>

                <v-btn color="black" style="background-color:#D9D9D9;" text @click="deleteMember">
                  {{ ("memberList.delete") }}
                </v-btn> -->
                      <!-- <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="dialog = false">
                  {{ $t("memberList.close") }}
                </v-btn>
                </v-card-actions> -->
                    </div>

                    <div
                      v-else-if="!viewDetailDeleteButtonShow"
                      class="div_border_top"
                      style="
                        align-items: center !important;
                        text-align: center !important;
                        margin-bottom: 20px;
                      "
                    >
                      <v-btn
                        type="button"
                        color=""
                        style="background-color: #d9d9d9; width: 140px"
                        @click="updateMember('viewDetails')"
                      >
                        {{ $t("memberList.save") }}
                      </v-btn>

                      <v-btn
                        type="button"
                        style="
                          background-color: #d9d9d9;
                          width: 140px;
                          margin-left: 10px;
                        "
                        @click="deleteMember"
                      >
                        {{ $t("memberList.delete") }}
                      </v-btn>
                      <v-btn
                        type="button"
                        color=""
                        style="
                          background-color: #d9d9d9;
                          width: 140px;
                          margin-left: 10px;
                        "
                        class="view_details_close_btn"
                        @click="dialog = false"
                      >
                        {{ $t("memberList.close") }}
                      </v-btn>
                      <!-- <v-card-actions class="vcardaction_buttons_withdelete">
                  <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="updateMember">
                    {{ $t("memberList.save") }}
                  </v-btn>

                  <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="deleteMember">
                    {{ $t("memberList.delete") }}
                  </v-btn>
                  <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="dialog = false">
                    {{ $t("memberList.close") }}
                  </v-btn>
                </v-card-actions> -->
                    </div>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </v-container>
        </base-card>
      </div>
    </div>
  </div>
</template>
<script>
  import '@/assets/css/style.css'
  import '@/assets/jquery/jquery.min.js'
  import '@/assets/google-font/stylesheet.css'
  import MemberDataService from '@/services/MemberDataService'
  // import Sidebar from '@/layouts/vertical-white/View.vue'
  import dayjs from 'dayjs'

  export default {
    name: 'MembersList',
    // components: {
    //    Sidebar
    // },
    data () {
      return {
        members: [],
        title: '',
        headers: [
          { text: 'Id', align: ' d-none', value: 'id' },
          { text: 'Key', align: 'start', sortable: false, value: 'id' },
          { text: 'Name', value: 'name', sortable: false },
          { text: 'E-mail', value: 'email', sortable: false },
          { text: 'Approval status', value: 'approvalStatus', sortable: false },
          { text: 'View License', value: 'viewLicense', sortable: false },
          { text: 'View details', value: 'viewdetails', sortable: false },
          { text: 'Registration Date', value: 'createdAt', sortable: false },
        ],
        validDetails: true,
        licenseNumberalreadyExistModal: false,
        viewDetailDeleteButtonShow: true,
        memberPageSwap: 'memberList',
        datatableCount: '',
        totalSubscribers: '',
        todaysSubscribers: '',
        filteredSubscribers: '',
        totalLogins: '',
        todaysLogins: '',
        filteredLogins: '',
        inputName: '',
        filterDateText: '',
        filterDateTextforStatistics: '',
        filterDateTextforall: '',
        filterDateTextforallStatistics: '',
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        startMenu: false,
        endMenu: false,
        id: null,
        currentMember: null,
        birthDate: {},
        message: '',
        licenseNumber: '',
        dialog: false,
        dialogTitle: '',
        dialogText: '',
        dialogButtonText: '',
        licenseTypes: ['Doctor', 'Nurse', 'Pharmacist'],
        months: [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
        ],
        snackbar: {
          color: null,
          icon: null,
          mode: null,
          position: 'top',
          text: null,
          timeout: 2500,
          title: null,
          visible: false,
        },

        pathname: null,

        btnToggle: null,
      }
    },
    // computed: {
    //   nameRules () {
    //     return [v => v.length <= 5 || this.$t('memberList.nameRulesMessage')]
    //   },
    //   emailRules () {
    //     return [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('memberList.emailRulesMessage')]
    //   },
    //   passwordRules () {
    //     return [v => !v || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/.test(v) || this.$t('memberList.passwordRulesMessage')]
    //   },
    //   // confirmpasswordRules() {
    //   //   return [((this.reverse(this.user.password)) === (this.reverse(this.user.confirmPassword))) || this.$t('memberList.confirmpasswordRulesMessage')];
    //   // },
    //   yearRules () {
    //     return [v => !v || /^[0-9]{4}$/.test(v) || this.$t('memberList.yearRulesMessage')]
    //   },
    //   dayRules () {
    //     return [v => /^[0-9]{0,2}$/.test(v) || this.$t('memberList.dayRulesMessagefortwodigits'), v => v < 32 || this.$t('memberList.dayRulesMessageformustbemonth')]
    //   },
    //   licenseNumberRules () {
    //     return [v => v.length <= 20 || this.$t('memberList.licenseNumberRulesMessage')]
    //   },
    //   requiredRule () {
    //     return [v => v != '' || this.$t('memberList.requiredfieldValidation')]
    //   },

    // },
    computed: {
      nameRules () {
        return [(v) => v.length <= 5 || this.$t('memberList.nameRulesMessage')]
      },
      emailRules () {
        // return [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('memberList.emailRulesMessage')];
        return [
          (v) =>
            !v ||
            /^[-a-zA-Z0-9_.]+@[-a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/.test(v) ||
            this.$t('memberList.emailRulesMessage'),
        ]
      },
      passwordRules () {
        return [
          (v) =>
            !v ||
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/.test(
              v,
            ) ||
            this.$t('memberList.passwordRulesMessage'),
        ]
      },
      // confirmpasswordRules() {
      //   return [((this.reverse(this.user.password)) === (this.reverse(this.user.confirmPassword))) || this.$t('memberList.confirmpasswordRulesMessage')];
      // },
      yearRules () {
        return [
          (v) =>
            !v || /^[0-9]{4}$/.test(v) || this.$t('memberList.yearRulesMessage'),
        ]
      },
      dayRules () {
        return [
          (v) =>
            /^[0-9]{0,2}$/.test(v) ||
            this.$t('memberList.dayRulesMessagefortwodigits'),
          (v) => v < 32 || this.$t('memberList.dayRulesMessageformustbemonth'),
        ]
      },
      licenseNumberRules () {
        return [
          (v) =>
            v.length <= 20 || this.$t('memberList.licenseNumberRulesMessage'),
        ]
      },
      requiredRule () {
        return [(v) => v != '' || this.$t('memberList.requiredfieldValidation')]
      },
      computedMemberListClass () {
        console.log(this.pathname)
        return this.pathname === '/MembersList' ? 'active' : ''
      },
    },
    watch: {
      $route (to, form) {
        if (to.path !== form.path) this.changeRoute(to.name)
      },
    },
    mounted () {
      this.changeRoute(window.location.pathname)
      // this.retrieveMembers()
      this.searchNameDate()
    // this.message = "";
    // this.getMember(this.$route.params.id);
    },
    methods: {
      clickNavBtn (path) {
        this.$router.push(path)
      },
      retrieveMembers () {
        MemberDataService.getAll()
          .then((response) => {
            console.log(response.data.length)
            this.datatableCount = response.data.length
            this.members = response.data.map(this.getDisplayMember)

            var data = {
              startDate: '',
              endDate: '',
            }

            console.log('Start Date : ' + data)
            MemberDataService.findByNameDate(data)
              .then((response) => {
                console.log(response.data)
              // this.datatableCount = response.data.length
              // console.log(this.datatableCount)
              })
              .catch((e) => {
                if (e.response.status === 401) {
                  console.log('Unauthorized')
                  localStorage.clear()
                  this.$router.push('/app/sessions/admin')
                } else {
                  console.log('Error is : ' + e)
                }
              })

            this.datatableCount = response.data.length
          // console.log(response.data);
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('/app/sessions/admin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      refreshList () {
        this.retrieveMembers()
      },
      refreshListWithDateInput () {
        // this.retrieveMembers()
        this.startDate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000,
        )
          .toISOString()
          .substr(0, 10)
        this.endDate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000,
        )
          .toISOString()
          .substr(0, 10)
        this.inputName = null
        this.btnToggle = 0
        this.searchNameDate()
      },

      removeAllMembers () {
        MemberDataService.deleteAll()
          .then((response) => {
            this.datatableCount = response.data.length
            console.log(response.data)
            this.refreshList()
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('/app/sessions/admin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      searchNameDate () {
        if (Date.parse(this.endDate) < Date.parse(this.startDate)) {
          this.SnackbarShow('endDateValidation')
          return false
        } else {
          var data = {
            name: this.inputName,
            startDate: this.startDate,
            endDate: this.endDate,
          }
          if (this.filterDateTextforall == 'all') {
            data.startDate = false
            data.endDate = false
          }
          // var startDateYear = JSON.stringify(new Date(data.startDate).getFullYear())
          // var startDateMonth = JSON.stringify(new Date(data.startDate).getMonth() + 1)
          // var startDateDay = JSON.stringify(new Date(data.startDate).getDate())
          // console.log("Start Date : " + " " + + " " + JSON.stringify(new Date(data.startDate).getMonth()) + " " + JSON.stringify(new Date(data.startDate).getDate()) + "");
          // data.startDate = [startDateYear, startDateMonth, startDateDay].join('-')
          // console.log("Date isss " + JSON.stringify(data))
          MemberDataService.findByNameDate(data)
            .then((response) => {
              // this.filterDateTextforall = null
              this.datatableCount = response.data.length
              this.members = response.data.map(this.getDisplayMember)
              console.log('Response is : ', response.data)
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('/app/sessions/admin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        }
      },

      searchDateForStatistics () {
        var data = {
          startDate: this.startDate,
          endDate: this.endDate,
        }
        if (this.filterDateTextforallStatistics == 'all') {
          data.startDate = false
          data.endDate = false
        }
        console.log('Start Date : ' + JSON.stringify(data))
        MemberDataService.findByNameDate(data)
          .then((response) => {
            this.filterDateTextforallStatistics = null
            this.filteredSubscribers = response.data.length
            // this.members = response.data.map(this.getDisplayMember);
            console.log(response.data)
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('/app/sessions/admin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      editMember (id, key) {
        if (key === 'viewLicense') {
          this.viewDetailDeleteButtonShow = true
          this.dialogTitle = 'memberList.viewLicense'
          this.dialogText = 'viewLicenseContainer'
          MemberDataService.get(id)
            .then((response) => {
              this.currentMember = response.data
              console.log(this.currentMember)
              this.birthDate.year = this.currentMember.birth.split('-')[0]
              this.birthDate.month = this.currentMember.birth.split('-')[1]
              this.birthDate.date = this.currentMember.birth.split('-')[2]
              console.log(response.data)
              this.dialog = true
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('/app/sessions/admin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        } else if (key === 'viewdetails') {
          this.viewDetailDeleteButtonShow = false
          this.dialogTitle = 'memberList.viewDetail'
          this.dialogText = 'viewDetailContainer'
          this.dialog = true
          MemberDataService.get(id)
            .then((response) => {
              this.currentMember = response.data
              console.log(this.currentMember)
              this.birthDate.year = this.currentMember.birth.split('-')[0]
              this.birthDate.month = this.currentMember.birth.split('-')[1]
              this.birthDate.date = this.currentMember.birth.split('-')[2]
              console.log(this.birthDate)
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('/app/sessions/admin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        }
      },

      // deleteMember(id) {
      //   MemberDataService.delete(id)
      //     .then(() => {
      //       this.dialog = false;
      //     })
      //     .catch((e) => {
      //       if (e.response.status === 401) {
      //         console.log("Unauthorized")
      //         localStorage.clear();
      //         this.$router.push("/app/sessions/admin")
      //       }
      //       else {
      //         console.log("Error is : " + e)
      //       }
      //     });
      // },

      getDisplayMember (member) {
        return {
          id: member.id,
          // key: Math.floor(100000 + Math.random() * 900000),
          name: member.name,
          email: member.email,
          approvalStatus: member.hcpApproval,
          createdAt: dayjs(member.createdAt).format('YYYY-MM-DD HH:mm'),
        }
      },

      getMember (id) {
        console.log('In Page : ' + id)
        MemberDataService.get(id)
          .then((response) => {
            this.currentMember = response.data
            console.log(response.data)
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('/app/sessions/admin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      updateMember (str) {
        if (str === 'viewLicense') {
          console.log('In viewLicense')
          // this.$refs.form.validate()
          console.log('Data is : ' + JSON.stringify(this.currentMember))
          this.currentMember.birth =
            this.birthDate.year +
            '-' +
            this.birthDate.month +
            '-' +
            this.birthDate.date
          MemberDataService.update(this.currentMember.id, str, this.currentMember)
            .then((response) => {
              if (response.data == 'LicenseNumber Already Exists') {
                this.licenseNumberalreadyExistModal = true
                console.log('LicenseNumber Already Exists')
                return false
              }
              this.dialog = false
              console.log(response.data)
              this.message = 'The member was updated successfully!'
              this.refreshList()
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('/app/sessions/admin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        }
        if (str === 'viewDetails') {
          console.log('In viewDetail')
          console.log('Before  ' + this.currentMember.name)
          this.$refs.form.validate()
          console.log('After')
          console.log('Data is : ' + JSON.stringify(this.currentMember))

          // if (this.birthDate.year > (new Date()).getFullYear()) {
          //   this.SnackbarShow("validateDate");
          //   return false;
          // }
          // if (this.birthDate.month > (new Date()).getMonth() + 1) {
          //   this.SnackbarShow("validateDate");
          //   return false;
          // }
          // if (this.birthDate.date > (new Date()).getDate()) {
          //   this.SnackbarShow("validateDate");
          //   return false;
          // }

          var q = new Date()
          var m = q.getMonth() + 1
          var d = q.getDate()
          var y = q.getFullYear()
          var currentDateString = y + '-' + m + '-' + d
          var currentDate = new Date(currentDateString)
          var tempDateString =
            this.birthDate.year +
            '-' +
            this.birthDate.month +
            '-' +
            this.birthDate.date
          var tempDate = new Date(tempDateString)

          console.log(currentDate + ' ' + currentDateString + ' ' + q.getDate())
          console.log(tempDate + ' ' + tempDateString)

          if (!this.validateDay()) {
            this.SnackbarShow('daymustbenumberofmonthValidate')
            return false
          }

          if (tempDate > currentDate) {
            this.SnackbarShow('validateDate')
            return false
          }

          this.currentMember.birth =
            this.birthDate.year +
            '-' +
            this.birthDate.month +
            '-' +
            this.birthDate.date
          if (
            this.currentMember.name != '' ||
            this.currentMember.email != '' ||
            this.currentMember.password != '' ||
            this.birthDate.year != '' ||
            this.birthDate.month != '' ||
            this.birthDate.month != null ||
            this.birthDate.date != '' ||
            this.currentMember.licenseType != '' ||
            this.currentMember.licenseType != null ||
            this.currentMember.licenseNumber != ''
          ) {
            console.log('After1')
            if (this.$refs.form.validate()) {
              console.log('After2')
              MemberDataService.update(
                this.currentMember.id,
                str,
                this.currentMember,
              )
                .then((response) => {
                  if (response.data == 'LicenseNumber Already Exists') {
                    this.licenseNumberalreadyExistModal = true
                    console.log('LicenseNumber Already Exists')
                    return false
                  }
                  this.dialog = false
                  console.log(response.data)
                  this.message = 'The member was updated successfully!'
                  this.refreshList()
                })
                .catch((e) => {
                  if (e.response.status === 401) {
                    console.log('Unauthorized')
                    localStorage.clear()
                    this.$router.push('/app/sessions/admin')
                  } else {
                    console.log('Error is : ' + e)
                  }
                })
            } else {
              this.$refs.form.validate()
              return false
            }
          } else {
            // this.$refs.form.validate();
            return false
          }
        }
      },

      deleteMember () {
        if (!confirm('삭제 하시겠습니까?')) {
          return
        }
        MemberDataService.delete(this.currentMember.id)
          .then((response) => {
            this.dialog = false
            console.log(response.data)
            this.refreshList()
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('/app/sessions/admin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      filterDate (filterDateText) {
        if (filterDateText == 'today') {
          console.log(
            new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            ).toISOString(),
          )
          this.startDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.endDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1week') {
          this.startDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.endDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setDate(this.startDate.getDate() - 7)
          newstartDate = new Date(newstartDate)
          this.startDate = new Date(
            newstartDate - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1month') {
          this.startDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.endDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setMonth(
            this.startDate.getMonth() - 1,
          )
          newstartDate = new Date(newstartDate)
          this.startDate = new Date(
            newstartDate - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '6month') {
          this.startDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.endDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setMonth(
            this.startDate.getMonth() - 6,
          )
          newstartDate = new Date(newstartDate)
          this.startDate = new Date(
            newstartDate - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1year') {
          this.startDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.endDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setFullYear(
            this.startDate.getFullYear() - 1,
          )
          newstartDate = new Date(newstartDate)
          this.startDate = new Date(
            newstartDate - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == 'all') {
          this.startDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.endDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
          this.filterDateTextforall = 'all'
        }
        this.searchNameDate()
      },

      filterDateforStatistics (filterDateTextforStatistics, flag) {
        if (flag) {
          if (filterDateTextforStatistics == 'today') {
            console.log(
              new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000,
              ).toISOString(),
            )
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1week') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setDate(
              this.startDate.getDate() - 7,
            )
            newstartDate = new Date(newstartDate)
            this.startDate = new Date(
              newstartDate - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1month') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setMonth(
              this.startDate.getMonth() - 1,
            )
            newstartDate = new Date(newstartDate)
            this.startDate = new Date(
              newstartDate - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '6month') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setMonth(
              this.startDate.getMonth() - 6,
            )
            newstartDate = new Date(newstartDate)
            this.startDate = new Date(
              newstartDate - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1year') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setFullYear(
              this.startDate.getFullYear() - 1,
            )
            newstartDate = new Date(newstartDate)
            this.startDate = new Date(
              newstartDate - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == 'all') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.filterDateTextforallStatistics = 'all'
            this.searchDateForStatistics()
          }
        } else {
          if (filterDateTextforStatistics == 'today') {
            console.log(
              new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000,
              ).toISOString(),
            )
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1week') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setDate(
              this.startDate.getDate() - 7,
            )
            newstartDate = new Date(newstartDate)
            this.startDate = new Date(
              newstartDate - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1month') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setMonth(
              this.startDate.getMonth() - 1,
            )
            newstartDate = new Date(newstartDate)
            this.startDate = new Date(
              newstartDate - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '6month') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setMonth(
              this.startDate.getMonth() - 6,
            )
            newstartDate = new Date(newstartDate)
            this.startDate = new Date(
              newstartDate - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1year') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setFullYear(
              this.startDate.getFullYear() - 1,
            )
            newstartDate = new Date(newstartDate)
            this.startDate = new Date(
              newstartDate - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == 'all') {
            this.startDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.endDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)
            this.filterDateTextforallStatistics = 'all'
            this.searchDateForStatistics()
          }
        }
      },

      memberRegistrationStatistics () {
        this.memberPageSwap = 'memberRegistrationStatistics'
      },

      memberLoginStatistics () {
        this.memberPageSwap = 'memberLoginStatistics'
      },

      endDateValidation () {
        if (Date.parse(this.endDate) < Date.parse(this.startDate)) {
          this.SnackbarShow('endDateValidation')
          return false
        }
        this.searchNameDate()
      },

      SnackbarShow (type) {
        switch (type) {
          case 'endDateValidation':
            this.snackbar = {
              color: '#FF5252',
              icon: 'mdi-alert-circle-outline',
              mode: 'multi-line',
              position: 'top',
              timeout: 2000,
              title: 'Warning',
              text: 'memberList.endDateValidation',
              visible: true,
            }
            break
          case 'validateDate':
            this.snackbar = {
              color: '#FF5252',
              icon: 'mdi-alert-circle-outline',
              mode: 'multi-line',
              position: 'top',
              timeout: 2000,
              title: 'Warning',
              text: 'memberList.validateDate',
              visible: true,
            }
            break
          case 'daymustbenumberofmonthValidate':
            this.snackbar = {
              color: '#FF5252',
              icon: 'mdi-alert-circle-outline',
              mode: 'multi-line',
              position: 'top',
              timeout: 2000,
              title: 'Warning',
              text: 'memberList.daymustbenumberofmonthValidation',
              visible: true,
            }
            break
        }
      },

      logoutAdmin () {
        MemberDataService.logoutAdmin()
          .then((response) => {
            console.log('Logout')
            this.currentMember = ''
            localStorage.clear()
            this.$router.push('/app/sessions/admin')
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('/app/sessions/admin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      isNumber (evt) {
        evt = evt || window.event
        var charCode = evt.which ? evt.which : evt.keyCode
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault()
        } else {
          return true
        }
      },

      validateDay () {
        console.log('In validate')
        if (
          this.birthDate.year < 1000 ||
          this.birthDate.year > 3000 ||
          this.birthDate.month == 0 ||
          this.birthDate.month > 12
        ) {
          return false
        }

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

        // Adjust for leap years
        if (
          this.birthDate.year % 400 == 0 ||
          (this.birthDate.year % 100 != 0 && this.birthDate.year % 4 == 0)
        ) {
          monthLength[1] = 29
        }

        // Check the range of the day
        if (
          this.birthDate.date > 0 &&
          this.birthDate.date <= monthLength[this.birthDate.month - 1]
        ) {
          return true
        }
        if (
          this.birthDate.date <= 0 &&
          this.birthDate.date > monthLength[this.birthDate.month - 1]
        ) {
          return false
        }
      },
      changeRoute (pathname) {
        if (['/MembersList', '/Statistics'].includes(pathname)) {
          this.pathname = pathname
        }
      },
    },
  }
</script>

<style lang="scss">
.mebmber_li_item a {
  color: black !important;
}

.inquiry_btn_button {
  border: 1px solid black !important;
  box-shadow: none !important;
}

.inquiry_btn_button:hover {
  background-color: #5a5a5a !important;
  color: white !important;
}

.inquiry_btn_button_view_license {
  border: 1px solid black !important;
  box-shadow: none !important;
  color: black !important;
}

.inquiry_btn_button_view_license:hover {
  background-color: #5a5a5a !important;
  color: white !important;
}

button.date_time_week_btngroup_button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  background-color: #5a5a5a;
  color: white !important;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: white;
  // color: black !important;
}

.date_time_week_btngroup_button {
  min-width: 0px !important;
  margin: 0px !important;
  height: 36px !important;
  border: 1px solid black !important;
  border-color: black;
  background-color: white;
}

.v-input__slot {
  min-height: 37px !important;
}

.main_admin_dashbord_new {
  // height: 100% !important;
  width: 100%;
  float: left;
}

body {
  margin: 0;
}

.topnav {
  background-color: #d9d9d9;
  overflow: hidden;
  height: 48px;
}

.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.topnav .icon {
  display: none;
}

.log_out_admin_dashbord {
  float: right !important;
}

@media screen and (max-width: 768px) {
  // .topnav a {
  //   display: none;
  // }

  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .mebmber_li_item {
    margin-top: 0px !important;
  }

  .log_out_admin_dashbord {
    float: left !important;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav {
    height: auto !important;
  }

  .main_admin_dashbord_new_first {
    width: 100% !important;
    height: auto !important;
    padding: 10px;
  }

  .main_admin_dashbord_new_second {
    width: 100% !important;
    height: auto !important;
  }
}

.main_admin_dashbord_new_first {
  width: 17%;
  height: 100%;
  float: left;
  background-color: #f3f3f3;
}

.main_admin_dashbord_new_second {
  width: 83%;
  height: 100%;
  float: left;
}

.mebmber_li_item {
  list-style-type: none;
  font-weight: 600;
  margin-top: 40px;
}

a {
  text-decoration: none;
  color: black;
}

// button.date_time_week_btngroup_button.btn.btn-outline-secondary.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
//   background-color: #5A5A5A;
//   color: white;
// }

button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  background-color: #5a5a5a;
  color: white !important;
}

// button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default .v-btn__content {
//   color: white !important;
// }

.email_read_only_modal .v-input__control .v-input__slot {
  background-color: #ededed;
}

.v-btn:before {
  background-color: #ffffff !important;
}

.v-text-field input {
  /* padding-top: 8px !important; */
  margin-top: 5px !important;
}

/* .member_text_field_slot v-input__control v-input__slot v-text-field__slot  input{
padding-top: 8px 0 8px !important;
} */
.list {
  max-width: 750px;
}

.datacountClass {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* .v-btn {
min-width: 100px;
} */

.admin_dashbord_content_member_list {
  /* margin-top: 3px !important; */
}

.v-input__prepend-outer {
  margin-top: 0px !important;
}

.v-input__append-inner {
  margin-top: 6px !important;
}

.v-text-field input {
  padding-top: 3px !important;
}

table th span {
  font-size: 14px !important;
  font-family: "Noto Sans" !important;
  color: black;
}

.v-data-table-header {
  background-color: #d9d9d9;
}

// .admin_dashbord_content_member_list_row {
//   margin-top: -18px !important;
//   margin-left: 5px !important;
// }

// .admin_dashbord_content_member_list_row_first {
//   margin-left: 5px !important;
// }

.vcardaction_buttons {
  margin: 15px 247px !important;
  /* justify-content: center !important; */
}

.vcardaction_buttons_withdelete {
  margin: 15px 174px !important;
}

.v_dialog_member {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 400px !important;
  font-family: "Noto Sans" !important;
}

li {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 400px !important;
  font-family: "Noto Sans" !important;
}

.approval_toggle_button {
  /* position: absolute !important; */
  // width: 849.31px !important;

  background: #ffffff !important;
}

.v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  max-height: 90%;
}

.v-select__slot {
  max-height: 36px !important;
  display: flex !important;
  align-items: center !important;
}

.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  width: 122px;
}

.approval_toggle_button button {
  width: 20% !important;
}

.approval_toggle_button button span {
  // color: black;
  word-break: break-all !important;
}

.v-btn__content {
  /* word-wrap: break-word; */
}

.name_details_span li {
  list-style-type: none;
}

@media screen and (max-width: 992px) {
  .view_license_dialog {
    min-width: fit-content !important;
  }
}

.view_license_main_footer {
  justify-content: center !important;
  text-align: center;
}

.view_license_main_footer button {
  background-color: #d9d9d9;
  border-radius: 0%;
  border: none;
  width: 150px;
  height: 45px;
  color: black;
}

ul.view_license_dialog
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn.v-btn {
  min-width: 230px;
  margin: 5px;
  /* border: 1px solid black !important; */
  // border-radius: 6px;
}

.approval_toggle_button.v-item-group.theme--light.v-btn-toggle {
  display: block;
}

@media only screen and (max-width: 992px) {
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    /* changed in button group */
    // display: block;
  }

  .v-application .d-inline-block {
    display: inline-block !important;
    word-break: break-word;
    width: 50%;
    float: left;
  }

  .v-card__text.v_dialog_member {
    overflow-x: hidden;
  }

  .view_license_dialog.list-group.list-group-horizontal {
    width: 100%;
    float: left;
  }
}

.dialog_member_row_test {
  margin: -22px 0px !important;
}

@media screen and (max-width: 487px) {
  .view_details_close_btn {
    margin-top: 2% !important;
  }
}

@media screen and (max-width: 599px) {
  .admin_dashbord_content_member_second_list {
    margin-top: -35px !important;
  }

  .inquiry_btn_button_main {
    margin-top: -40px;
  }

  .dialog_member_col_test {
    margin: 4px !important;
    padding: 0px !important;
  }

  .dialog_member_row_test_down {
    margin: 0px !important;
    padding: 4px !important;
  }
}

@media screen and (max-width: 960px) {
  .date_time_week_btngroup_main_class {
    margin-top: -41px !important;
  }
}

@media screen and (min-width: 960px) {
  .date_time_week_btngroup_toggle {
    display: block !important;
  }

  .date_time_week_btngroup {
    padding-top: -20px;
  }
}

@media screen and (max-width: 555px) {
  .date_time_week_btngroup_toggle {
    display: block !important;
  }
  .row .admin_dashbord_content_member_list_row_first {
    .admin_dashbord_content_member_list {
      width: 50%;
      max-width: 50%;
      .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
        > .v-input__control
        > .v-input__slot,
      .v-text-field.v-text-field--enclosed .v-text-field__details {
        font-size: 14px;
        padding: 0 8px;
      }
    }
  }

  div.admin_dashbord_content_member_second_list {
    margin-top: 0 !important;
  }
  div.date_time_week_btngroup_main_class {
    margin-top: -20px !important;
    .v-btn {
      font-size: 11px;
    }
  }
  div.inquiry_btn_button_main {
    margin-top: 0 !important;
  }
  .v-data-table-header-mobile {
    display: none;
  }
}

// .menuable__content__active{
//   width: 122px;
// }

.date_time_week_btngroup_toggle {
  // border: 1px solid black;
}

img {
  vertical-align: middle;
  border: 0;
}

.startdate_picker {
  width: 290px !important;
}

.div_border_top {
  border-top: 1px solid;
  padding-top: 10px;
}
</style>
